import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
const rootElem = document.getElementById('root');
const root = ReactDOM.createRoot(rootElem);


root.render(

    <App />

);

reportWebVitals();
